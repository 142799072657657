// infobox options - http://htmlpreview.github.io/?https://github.com/googlemaps/v3-utility-library/blob/master/infobox/docs/reference.html

var ProdoMapHelpers = {
    InfoBoxOptions: {
        disableAutoPan: false,
        maxWidth: 0,
        pixelOffset: new google.maps.Size(-125, -70),
        zIndex: null,
        boxClass: "m-map_pin_content__wrapper",
        closeBoxURL: "http://www.google.com/intl/en_us/mapfiles/close.gif",
        alignBottom: true,
        infoBoxClearance: new google.maps.Size(20, 20),
        isHidden: false,
        pane: "floatPane",
        enableEventPropagation: false,
    },
    InfoBox: {},
    InfoWindow: new google.maps.InfoWindow({}),
    DefaultZoom: 16,
    AddMarker: function (map, longLat, popupContent, pin, additionalOptions) {
        // set defaults

        if (typeof (additionalOptions) === 'undefined' || additionalOptions === null) { additionalOptions = {}; }

        var markerOptions = {
            map: map,
            position: longLat,
            icon: pin,
            popupContent: popupContent
        };

        // extend the marker options with any additional options provided

        $.extend(markerOptions, additionalOptions);

        var marker = new google.maps.Marker(markerOptions);

        google.maps.event.addListener(marker, 'click', function () {
        
            if (this.popupContent) {
                // if we have the custom infobox plugin
                ProdoMapHelpers.infoBoxOptions
                if (typeof InfoBox !== 'undefined') {
                    var infoBoxOptions = ProdoMapHelpers.InfoBoxOptions;
                    infoBoxOptions.content = popupContent;
                    ProdoMapHelpers.InfoBox = new InfoBox(infoBoxOptions);
                    ProdoMapHelpers.InfoBox.open(map, marker);
                    map.panTo(ProdoMapHelpers.InfoBox.getPosition());
                } else {
                    ProdoMapHelpers.InfoWindow.setContent(popupContent);
                    ProdoMapHelpers.InfoWindow.open(map, this);
                }
            }
        
        
        });

        return marker;
    },
    AddMarkersFromJson: function (map, jsonLocations, numberMarkers) {
        // null checking

        if (typeof (map) === 'undefined' || typeof (jsonLocations) === 'undefined') { return false; }

        // lets get mapping

        var totalPins = jsonLocations.length,
            markers = [];

        if (totalPins) {
            var bounds = new google.maps.LatLngBounds();

            $.each(jsonLocations, function (index, location) {
                var longLat = new google.maps.LatLng(location.Latitude, location.Longitude),
                    additionalMarkerOptions = {
                        id: location.Id
                    };

                    if(numberMarkers){
                       $.extend(additionalMarkerOptions, {label: {
                            text: (index + 1).toString(),
                            color: "#ffffff"
                        }});
                    }


                var marker = ProdoMapHelpers.AddMarker(map, longLat, location.PopupContent, location.Pin, additionalMarkerOptions);

                markers.push(marker);

                bounds.extend(longLat);
            });

            map.initialZoom = true;
            map.fitBounds(bounds);
            map.setCenter(bounds.getCenter());

        }

        return markers;
    },
    BindDefaultMapEvents: function (map) {
        google.maps.event.addDomListener(window,
                      "resize",
                      function () {
                          var center = map.getCenter();
                          map.setCenter(center);
                      });

        google.maps.event.addListener(map,
            'bounds_changed',
            function (event) {
                if (this.getZoom() > ProdoMapHelpers.DefaultZoom && this.initialZoom) {
                    this.setZoom(ProdoMapHelpers.DefaultZoom);
                    this.initialZoom = false;
                }
            });


    },
    InitializeMap: function (mapElement, mapOptions) {
        // check if element is jquery object first

        if (mapElement instanceof jQuery) { mapElement = mapElement[0]; }

        // now lets get to work

        var defaultMapOptions = {
            zoom: ProdoMapHelpers.DefaultZoom,
            scrollwheel: false,
            center: new google.maps.LatLng(53.191372, -2.8377752),
            initialZoom: true,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControl: false,
        };

        if (typeof (mapOptions) !== 'undefined') {
            // set default values if none are provided

            $.extend(defaultMapOptions, mapOptions);
        }

        return new google.maps.Map(mapElement, defaultMapOptions);
    }
};
